.footer {
	clear: both;
	font-size: 0.9em;
	padding: 1em 0;
	background-color: $color-blue;
	color: $color-white;
	text-align: center;
	a {
		color: $color-white;
		text-decoration: underline;
	}
	@include mq(tablets) {
		font-size: 0.8em;
	}
	address p:first-of-type {
		margin-top: 0;
	}
}

.footer-heading {
	margin-bottom: 0.25em;
	font-weight: 700;
	color: $color-white;
}

.footer_info-list {
	margin-bottom: 1em;
	padding: 0;
	list-style: none;
	font-size: 0.9rem;
	text-align: center;
	display: block;
	@include mq(tablets) {
		display: none;
	}
}
.copyright {
	padding: 0.5em;
	text-align: center;
	font-size: 0.8em;
	@include mq(tablets) {
		font-size: 0.8em;
	}
}