.content-wrap {
    @include clearfix;
    max-width: $max-width;
    margin: 0 auto;
}

.content_image {
    @include clearfix;
    width: 100%;
    min-height: 20em;
    margin: 2em auto;
    @include mq(wide_tablets) {
        float: left;
        width: 25%;
        flex: 1 1 25%;
        margin: 0 0 2em;
    }
}

.content_image-hp {
    background: url("/images/hp-content-img-small.jpg") no-repeat;
    @include mq(31em) {
        background-image: url("/images/hp-content-img-med.jpg");
    }
    @include mq(tablets) {
        width: 48%;
        flex: 1 1 48%;
        margin-left: 2em;
        margin-bottom: 0;
        background-image: url("/images/hp-content-img.jpg");
    }
    @include mq(wide_tablets) {
         background-image: url("/images/hp-content-img-med.jpg");
    }
}

.content_image-about {
    background: url("/images/vineyard-small.jpg") no-repeat;
    @include mq(31em) {
        background-image: url("/images/vineyard-med.jpg");
    }
    @include mq(tablets) {
        background-image: url("/images/vineyard.jpg");
    }
    @include mq(wide_tablets) {
        background-image: url("/images/vineyard-med.jpg");
    }

}

.content_image-services {
    background: url("/images/services-small.jpg") no-repeat;
    @include mq(31em) {
        background-image: url("/images/services-med.jpg");
    }

    @include mq(tablets) {
        background-image: url("/images/services.jpg");
    }
     @include mq(wide_tablets) {
        background-image: url("/images/services-med.jpg");
    }
}

.content_image {
    background-size: cover;
    background-position: center;
}

.content_image-newsletter {
    background: url("/images/newsletter-small.jpg") no-repeat;
    background-position: bottom;
    @include mq(31em) {
        background-image: url("/images/newsletter.jpg");
    }
}

.content_image-specials {
    background: url("/images/specials.jpg") no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: 30em;
    @include mq(31em) {
        background-image: url("/images/specials-med.jpg");
    }
    @include mq(tablets) {
        background-image: url("/images/specials.jpg");
    }
    @include mq(wide_tablets) {
        background-image: url("/images/specials-med.jpg");
    }
}

.content_text {
    @include clearfix;
    @include mq(wide_tablets) {
        float: left;
        width: 48%;
        flex: 1 1 48%;
    }
}