.content_text-secondary-contact {
    z-index: 10001;
}

.contact-info-wrap {
    @include clearfix;
    padding: 0;
    font-size: 0.9em;
    text-align: center;
    @include mq(wide_handhelds) {
        font-size: 1em;
    }
    @include mq(wide_tablets) {
        float: left;
        width: 40%;
        height: 20em;
        margin-top: 2em;
        text-align: left;
    }
    h4 {
        margin-bottom: 0.25em;
    }
    p:first-child {
        margin-top: 0;
    }
}

.form-contact {
    margin-bottom: 2em;
}

.banana {
    position: absolute;
    height: 0;
    opacity: 0;
}