button, .button {
    padding: 0.5em 1em;
    background-color: $color-blue;
    text-align: center;
    color: $color-white;
    border: none;
    &:hover {
        background-color: lighten($color-blue, 10%);
    }
}

a.button {
    color: $color-white;
    text-decoration: none;
}

.button-large {
    padding: 1em 1.5em;
    transition: 0.5s ease-in;
    background-color: rgba($color-white, 0.8);
    width: 100%;
    text-align: center;
    color: $color-blue;
    font-size: 1.2em;
    transition: all 0.5s linear;
    font-weight: 700;
    @include mq(wide_handhelds) {
        width: auto;
        font-weight: 400;
    }

    &:hover {
        text-decoration: none;
        background-color: rgba($color-blue, 0.7);
        color: $color-white;
        transition: all 0.5s ease-in;
        animation: move;
    }
}

.button-services {
    display: block;
    margin: 2em auto;
    max-width: 15em;
    &:hover {
        cursor: pointer;
    }
}

.button-services-hp {
    font-size: 0.8em;
}