h1, h2, h3, h4, h5, h6 {
    color: $color-blue;
    line-height: 1.2;
    font-weight: 400;
}

.content-heading {
    max-width: 90%;
    padding-bottom: 0.25em;
    border-bottom: 1px solid $color-blue;
}