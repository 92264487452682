// mixins + normalize + common
@import "common/vars", "public/vars", "common/mixins", "public/mixins", "common/media", "common/common";

// global styles on the most common elements
html {
    font: 100%/1 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5;
    color: $text-color;
    letter-spacing: 1px;
}

a {
    color:rgba($color-blue, 0.8);
    text-decoration: none;
    &:hover {
        color: $color-blue;
        text-decoration: underline;
    }
}

.-text-bold {
    font-weight: 700;
}

.-text-center {
    text-align: center;
}

// load common styles
@import "common/forms", "common/pagination", "common/alerts";

// public only styles
@import "public/content",
    "public/secondary",
    "public/buttons",
    "public/header",
    "public/footer",
    "public/testimonials",
    "public/headings"
    ;

// page specific
@import "public/p_index", "public/p_contact";