.testimonial-img_bar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #007c8b url(/images/testimonials-small.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    @include mq(32em) {
        background-image: url(/images/testimonials-med.jpg);
    }
    @include mq(wide_tablets) {
        background-image: url(/images/testimonials.jpg)
    }
    @include mq(71.25em) {
        background-attachment: fixed;
    }
}

.testimonials-heading {
    margin-bottom: 0;
    padding: 5.5em 0;
    text-align: center;
    color: $color-white;
    font-weight: 700;
}

.testimonials-wrap {

    border-top: 1px solid $color-blue;
}
.testimonial-large_font {
    font-size: 1.5rem;
}

.testimonials-posts {
    max-width: 40em;
    margin: 0 auto 2em;
    padding: 0 1em;
    @include mq(tablets) {
        padding: 0;
    }
}

.testimonial-post {
    background: url(/images/quote.png) no-repeat;
    background-position: right 0;
    margin-top: 2em;
    padding-top: 4em;
    padding-bottom: 2em;
    border-bottom: 1px solid rgba($color-blue, 0.5);
    h3 {
        font-weight: 700;
    }
}

.testimonial-name, .testimonial-date {
    text-align: right;
}

.testimonial-name {
    font-weight: 700;
}

.testimonial-date {
    font-size: 0.9em;
    color: rgba($color-black, 0.5);
    font-style: italic;

}

.testimonial-button_wrap {
    margin-bottom: 2em;
    text-align: center;
}