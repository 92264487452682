.secondary-page_wrap {
    max-width: $max-width;
    margin: 1em auto;
    padding: 0 1em;
    @include mq(wide_tablets) {
        display: flex;
    }
}

.content_text-secondary {
    @include mq(tablets) {
        margin-left: 12em;
        margin-right: 2em;
    }
}

// targeting p and h4 tags within Perch
.secondary-content-wrap {
    p {
        font-size: 0.9em;
        font-style: italic;
        color: rgba($color-black, 0.8);
        margin-top: 0.25em;
    }
    h4 {
        margin-bottom: 0.5em;
        font-weight: 700;
    }
}