.header-wrap {
    border-bottom: 0.8em solid $color-blue;
    @include mq(tablets) {
        border-bottom: 24px solid $color-blue;
    }
}

.header {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 1em;

    @include mq(82em) {
        padding: 0;
    }
}

.header-content-wrap {
    margin-right: auto;
    padding-bottom: 1em;
    @include mq(tablets) {
        height: 9em;
        padding-bottom: 0;
    }
}

.header-image {
    display: block;
    width: 90%;
    max-height: 6em;
    margin: 1.25em auto 0;
    text-align: center;
    @include mq(tablets) {
        float: left;
        width: auto;
        margin: 1em 0 0;
    }
}
.header-nav_wrap {
    @include clearfix;
    position: relative;
    max-width: $max-width;
    @include mq(tablets) {
        margin: 0 auto -20.75em;
    }
    @include mq(82em) {
        padding: 0;
    }
}

.header-nav {
    padding-left: 0;
    cursor: pointer;
    list-style: none;

    @include mq(tablets) {
        margin: 0 0 -1.25em;
        max-width: 10em;
        padding-left: 0;
    }

    a {
        color: $color-white;
        text-decoration: none;
    }
}

.header_nav-item {
    background-color: $color-blue;
    padding: 0.75em;
    margin-bottom: 0.3em;
    color: $color-white;
    transition: 0.5s;
    line-height: 1;
    text-align: center;
    font-weight: 200;
    @include mq(tablets) {
        letter-spacing: 1.2px;
        width: 10em;
        margin-bottom: 0.2em;
        text-align: left;
        &:hover {
            background-color: lighten($color-blue, 10%);
            transition: 0.5s;
        }
    }
}

.header_nav-item a {
    @include mq(tablets) {
        padding: 0.75em 70% 0.75em 0;
    }
}

.header-info-wrap {
    display: none;
    font-size: 0.9em;
    @include mq(tablets) {
        display: block;
        padding-top: 1em;
        list-style: none;
        text-align: right;

        li:first-of-type {
            margin-bottom: 1em;
        }
    }
}