.hp-hero_bar {
    padding: 3em 0 4em;
    background: $color-blue url("/images/towel-image-small.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    @include mq(32em) {
        background-image: url("/images/towel-image-med.jpg");
    }
    @include mq(tablets) {
        padding: 6em 0 10em;
    }
    @include mq(wide_tablets) {
        background-image: url("/images/towel-image.jpg");
    }
}

.hp-hero_bar-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 20em;
    margin: 0 auto;
    text-align: center;
}

.hp-hero_bar-text {
    margin: 1em 0;
    @include mq(tablets) {
        margin: 2em 0;
    }
}

.hero_bar-heading {
    text-align: center;
    color: $color-white;
    font-weight: 400;
    margin: 0;
}

.hero_bar-heading-relax {
    font-size: 3em;
    border-bottom: 1px solid $color-white;
    @include mq(tablets) {
        font-size: 4em;
        border-width: 3px;

    }
}
.hero_bar-heading-rejuvenate {
    font-size: 1.5em;
    padding-top: 0.25rem;
    border-bottom: 1px solid $color-white;
    @include mq(tablets) {
        font-size: 2em;
        border-width: 3px;
    }
}
.hero_bar-heading-renew {
    font-size: 2.6em;
    margin-bottom: 2rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    border-bottom: 1px solid $color-white;
    @include mq(tablets) {
        font-size: 3.5em;
        border-width: 3px;
    }
}

.hp-content-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 2em auto;
    padding: 0 1em;
    @include mq(tablets) {
        margin: 5em auto;
    }
}

.review-heading {
    margin: 2em 0;
    text-align: center;
    font-weight: 700;
}

.review_logo-wrap {
    margin-bottom: 4em;
    text-align: center;
    @include mq(tablets) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.review {
    display: block;
    margin-bottom: 2em;
    @include mq(tablets) {
        margin-right: 4em;
        margin-bottom: 0;
        &:last-of-type {
            margin-right: 0;
        }
    }
}